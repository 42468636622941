<template>
  <div class="help" ref="official">
    <div class="header-bar">
      <img src="@/assets/img/logo.png" class="logo-img" style="margin: 0;" />
      <van-popover v-model:show="showPopover" :actions="actions" placement="bottom-end" @select="onSelect" v-if="mode == 'xs'">
        <template #reference>
          <q-icon name="menu" size="40px" style="color: #fff;"></q-icon>
        </template>
      </van-popover>
      <div class="menu" v-if="mode == 'md'">
        <div class="menu-item" v-for="(item,index) in actions" :key="index" @click="onSelect(item)">
          {{ item.text }}
        </div>  
      </div>
    </div>

    <div class="box">
      <div class="content" v-html="articlecontent"></div>
    </div>
  </div>
</template>

<script>
// import HeaderBar from '@/components/HeaderBar'
import { ref } from 'vue'
import { Popover } from 'vant';

export default {
  name: '',
  components: {
    // HeaderBar
    [Popover.name]: Popover,
  },
  setup() {
    return {
      mode: ref("md"),
      select_id: ref(""),
      articlecontent: ref(""),
      showPopover: ref(false),
      actions: [
        {text: "首页", lx: 3, val: 'Official'},
				{text: "关于币安", lx: 1, val: 11},
				{text: "关于LBD", lx: 1,  val: 12},
				{text: "LBD生态", lx: 1,  val: 13},
				{text: "LBD前景", lx: 1,  val: 14}
			],
    }
  },
  created() {
    this.select_id = this.$route.query.id
    this.getArticle()
  },
  mounted() {
    let _this = this
    let clientWidth = this.$refs.official.offsetWidth
    _this.mode = clientWidth <= 768 ? "xs" : "md"
    _this.$store.commit("setMode", _this.mode);

    window.onresize = function() {
      _this.$nextTick(() => {
        let clientWidth = _this.$refs.official.offsetWidth
        _this.mode = clientWidth <= 768 ? "xs" : "md"
        _this.$store.commit("setMode", _this.mode);
      })
    } 
  },
  methods: {
    back() {
      this.$router.back()
    },
    onSelect(e) {
			if(e.lx == 1) {
        if(this.$route.name == "Help") {
          this.select_id = e.val
          this.getArticle()
          return
        }
				this.$router.push("Help?id=" + e.val)
			}
			if(e.lx == 2) {
				window.open(e.val)
			}
			if(e.lx == 3) {
				this.$router.push(e.val)
			}
		},
    getArticle() {
      let _this = this
      _this.$request.post(
        "api/Article/Get",
        {
          select_id: _this.select_id
        },
        (res) => {
          if(res.data.code == 0) {
            _this.$q.dialog({
							message: res.data.msg,
						});
          }
          let data = res.data.data
          _this.articlecontent = data.articlecontent
        }
      )
    },
  }
}
</script>

<style scoped>
.help {
  min-height: 100vh;
  background-image: linear-gradient(to left bottom, #180322, #1d0925, #220e27, #27122a, #2d152d);
}

.box {
  padding: 10px;
}

.logo-img {
  width: 40px;
}

.content {
  padding: 0 15px 15px;
  margin-bottom: 14px;
  border-radius: 10px;
  color: #fff;
}

.content:deep(img) {
  margin: 0 auto;
  display: block;
}

.news-title {
  font-weight: bold;
}

.news-date {
  padding: 10px 0;
  color: gray;
  font-size: 13px;
}

.header-bar {
  box-sizing: border-box;
  padding: 16px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transparent {
  background: transparent;
}

.tops-heads {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tops-row {
  display: flex;
  align-items: center;
}

.back-wrap {
  margin-top: 10px;
}

.menu {
  display: flex;
  color: #fff;
}

.menu-item {
  font-size: 16px;
  font-weight: bold;
  padding: 0 15px;
  cursor: pointer;
}


@media screen and (min-width: 1000px) {
  .content {
    font-size: 16px;
  }

  .logo-img {
    width: 55px;
  }
}
</style>